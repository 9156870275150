import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/app/docs/node_modules/@uchiru/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`StyledOcticon renders an `}<a parentName="p" {...{
        "href": "https://octicons.github.com"
      }}>{`Octicon`}</a>{` with common system props, including `}<inlineCode parentName="p">{`color`}</inlineCode>{`, margin, and padding.`}</p>
    <h2>{`Default example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<StyledOcticon icon={Check} size={32} color="green.5" mr={2} />
<StyledOcticon icon={X} size={32} color="red.5" />
`}</code></pre>
    <h2>{`System props`}</h2>
    <p>{`StyledOcticon components get `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{` system props. Read our `}<a parentName="p" {...{
        "href": "/about/system-props"
      }}>{`System Props`}</a>{` doc page for a full list of available props.`}</p>
    <h2>{`Component props`}</h2>
    <p>{`StyledOcticon passes all of its props except the common system props down to the `}<a parentName="p" {...{
        "href": "https://github.com/primer/octicons/tree/master/lib/octicons_react#usage"
      }}>{`Octicon component`}</a>{`, including:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`ariaLabel`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Specifies the `}<inlineCode parentName="td">{`aria-label`}</inlineCode>{` attribute, which is read verbatim by screen readers`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`icon`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Octicon`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://github.com/primer/octicons/tree/master/lib/octicons_react"
            }}>{`Octicon component`}</a>{` used in the component`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`size`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Number`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`16`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sets the uniform `}<inlineCode parentName="td">{`width`}</inlineCode>{` and `}<inlineCode parentName="td">{`height`}</inlineCode>{` of the SVG element`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`verticalAlign`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`text-bottom`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sets the `}<inlineCode parentName="td">{`vertical-align`}</inlineCode>{` CSS property`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      